exports.pmsPoints = [
   // LV01
   { reportName: 'LV01_Iaverage', name:'LV01 Current Average', unit:'A', group:['LV01']},
   { reportName: 'LV01_Vaverage', name:'LV01 Voltage Average', unit:'V', group:['LV01']},
   { reportName: 'LV01_Ptot', name:'LV01 Power Total', unit:'kW', group:['LV01']},
   { reportName: 'LV01_E', name:'LV01 Energy', unit:'kWh', group:['LV01']},
   { reportName: 'LV01_PF', name:'LV01 Power Factor', unit:'', group:['LV01']},
   // DB-PR1
   { reportName: 'DB-PR1_Iaverage', name:'DB-PR1 Current Average', unit:'A', group:['DB-PR1']},
   { reportName: 'DB-PR1_Vaverage', name:'DB-PR1 Voltage Average', unit:'V', group:['DB-PR1']},
   { reportName: 'DB-PR1_Ptot', name:'DB-PR1 Power Total', unit:'kW', group:['DB-PR1']},
   { reportName: 'DB-PR1_E', name:'DB-PR1 Energy', unit:'kWh', group:['DB-PR1']},
   { reportName: 'DB-PR1_PF', name:'DB-PR1 Power Factor', unit:'', group:['DB-PR1']},
   // DB1
   { reportName: 'DB1_Iaverage', name:'DB1 Current Average', unit:'A', group:['DB1']},
   { reportName: 'DB1_Vaverage', name:'DB1 Voltage Average', unit:'V', group:['DB1']},
   { reportName: 'DB1_Ptot', name:'DB1 Power Total', unit:'kW', group:['DB1']},
   { reportName: 'DB1_E', name:'DB1 Energy', unit:'kWh', group:['DB1']},
   { reportName: 'DB1_PF', name:'DB1 Power Factor', unit:'', group:['DB1']},
   // DB2
   { reportName: 'DB2_Iaverage', name:'DB2 Current Average', unit:'A', group:['DB2']},
   { reportName: 'DB2_Vaverage', name:'DB2 Voltage Average', unit:'V', group:['DB2']},
   { reportName: 'DB2_Ptot', name:'DB2 Power Total', unit:'kW', group:['DB2']},
   { reportName: 'DB2_E', name:'DB2 Energy', unit:'kWh', group:['DB2']},
   { reportName: 'DB2_PF', name:'DB2 Power Factor', unit:'', group:['DB2']},
   // DB3
   { reportName: 'DB3_Iaverage', name:'DB3 Current Average', unit:'A', group:['DB3']},
   { reportName: 'DB3_Vaverage', name:'DB3 Voltage Average', unit:'V', group:['DB3']},
   { reportName: 'DB3_Ptot', name:'DB3 Power Total', unit:'kW', group:['DB3']},
   { reportName: 'DB3_E', name:'DB3 Energy', unit:'kWh', group:['DB3']},
   { reportName: 'DB3_PF', name:'DB3 Power Factor', unit:'', group:['DB3']},
   // DB4
   { reportName: 'DB4_Iaverage', name:'DB4 Current Average', unit:'A', group:['DB4']},
   { reportName: 'DB4_Vaverage', name:'DB4 Voltage Average', unit:'V', group:['DB4']},
   { reportName: 'DB4_Ptot', name:'DB4 Power Total', unit:'kW', group:['DB4',]},
   { reportName: 'DB4_E', name:'DB4 Energy', unit:'kWh', group:['DB4']},
   { reportName: 'DB4_PF', name:'DB4 Power Factor', unit:'', group:['DB4']},
   // DB1.1
   { reportName: 'DB1.1_Iaverage', name:'DB1.1 Current Average', unit:'A', group:['DB1.1',]},
   { reportName: 'DB1.1_Vaverage', name:'DB1.1 Voltage Average', unit:'V', group:['DB1.1']},
   { reportName: 'DB1.1_Ptot', name:'DB1.1 Power Total', unit:'kW', group:['DB1.1']},
   { reportName: 'DB1.1_E', name:'DB1.1 Energy', unit:'kWh', group:['DB1.1']},
   { reportName: 'DB1.1_PF', name:'DB1.1 Power Factor', unit:'', group:['DB1.1']},
   // DB1.2
   { reportName: 'DB1.2_Iaverage', name:'DB1.2 Current Average', unit:'A', group:['DB1.2']},
   { reportName: 'DB1.2_Vaverage', name:'DB1.2 Voltage Average', unit:'V', group:['DB1.2']},
   { reportName: 'DB1.2_Ptot', name:'DB1.2 Power Total', unit:'kW', group:['DB1.2']},
   { reportName: 'DB1.2_E', name:'DB1.2 Energy', unit:'kWh', group:['DB1.2',]},
   { reportName: 'DB1.2_PF', name:'DB1.2 Power Factor', unit:'', group:['DB1.2']},
   // DB2.1
   { reportName: 'DB2.1_Iaverage', name:'DB2.1 Current Average', unit:'A', group:['DB2.1']},
   { reportName: 'DB2.1_Vaverage', name:'DB2.1 Voltage Average', unit:'V', group:['DB2.1']},
   { reportName: 'DB2.1_Ptot', name:'DB2.1 Power Total', unit:'kW', group:['DB2.1']},
   { reportName: 'DB2.1_E', name:'DB2.1 Energy', unit:'kWh', group:['DB2.1']},
   { reportName: 'DB2.1_PF', name:'DB2.1 Power Factor', unit:'', group:['DB2.1']},
   // DB2.2
   { reportName: 'DB2.2_Iaverage', name:'DB2.2 Current Average', unit:'A', group:['DB2.2']},
   { reportName: 'DB2.2_Vaverage', name:'DB2.2 Voltage Average', unit:'V', group:['DB2.2']},
   { reportName: 'DB2.2_Ptot', name:'DB2.2 Power Total', unit:'kW', group:['DB2.2']},
   { reportName: 'DB2.2_E', name:'DB2.2 Energy', unit:'kWh', group:['DB2.2']},
   { reportName: 'DB2.2_PF', name:'DB2.2 Power Factor', unit:'', group:['DB2.2']},
   // DB3.1
   { reportName: 'DB3.1_Iaverage', name:'DB3.1 Current Average', unit:'A', group:['DB3.1']},
   { reportName: 'DB3.1_Vaverage', name:'DB3.1 Voltage Average', unit:'V', group:['DB3.1']},
   { reportName: 'DB3.1_Ptot', name:'DB3.1 Power Total', unit:'kW', group:['DB3.1']},
   { reportName: 'DB3.1_E', name:'DB3.1 Energy', unit:'kWh', group:['DB3.1']},
   { reportName: 'DB3.1_PF', name:'DB3.1 Power Factor', unit:'', group:['DB3.1']},
   // DB3.2
   { reportName: 'DB3.2_Iaverage', name:'DB3.2 Current Average', unit:'A', group:['DB3.2']},
   { reportName: 'DB3.2_Vaverage', name:'DB3.2 Voltage Average', unit:'V', group:['DB3.2']},
   { reportName: 'DB3.2_Ptot', name:'DB3.2 Power Total', unit:'kW', group:['DB3.2']},
   { reportName: 'DB3.2_E', name:'DB3.2 Energy', unit:'kWh', group:['DB3.2']},
   { reportName: 'DB3.2_PF', name:'DB3.2 Power Factor', unit:'', group:['DB3.2']},
   // DB4.1
   { reportName: 'DB4.1_Iaverage', name:'DB4.1 Current Average', unit:'A', group:['DB4.1']},
   { reportName: 'DB4.1_Vaverage', name:'DB4.1 Voltage Average', unit:'V', group:['DB4.1']},
   { reportName: 'DB4.1_Ptot', name:'DB4.1 Power Total', unit:'kW', group:['DB4.1']},
   { reportName: 'DB4.1_E', name:'DB4.1 Energy', unit:'kWh', group:['DB4.1']},
   { reportName: 'DB4.1_PF', name:'DB4.1 Power Factor', unit:'', group:['DB4.1']},
   // DB4.2
   { reportName: 'DB4.2_Iaverage', name:'DB4.2 Current Average', unit:'A', group:['DB4.2']},
   { reportName: 'DB4.2_Vaverage', name:'DB4.2 Voltage Average', unit:'V', group:['DB4.2']},
   { reportName: 'DB4.2_Ptot', name:'DB4.2 Power Total', unit:'kW', group:['DB4.2']},
   { reportName: 'DB4.2_E', name:'DB4.2 Energy', unit:'kWh', group:['DB4.2']},
   { reportName: 'DB4.2_PF', name:'DB4.2 Power Factor', unit:'', group:['DB4.2']},
]
