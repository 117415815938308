<template>
  <v-toolbar
      dark
      app
      :color="$root.themeColor"
      >
      <v-toolbar-title>
         <v-toolbar-side-icon flat :color="$root.themeTextColor" @click="toggleNavigationBar"></v-toolbar-side-icon>
      </v-toolbar-title>
      <!-- <breadcrumbs /> -->
      <v-spacer></v-spacer>
  </v-toolbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
        name: 'name',
        userId: "userId",
        token: "token",
        role: "level"
    }),
    userRoleObj () {
      if(this.role === 'SupAdmin') return { roleStr: 'Siêu quản trị', roleColor: "indigo" }
      if(this.role === 'Admin') return { roleStr: 'Quản trị', roleColor: "red" }
      if(this.role === 'Operator') return { roleStr: 'Nhân viên', roleColor: "blue"}
      if(this.role === 'Engineer') return { roleStr: 'Ks lắp đặt', roleColor: "orange" }
      if(this.role === 'Guest') return { roleStr: 'Khách', roleColor: "teal" }
      return { roleStr: 'Khách', roleColor: "teal" }
                
    }
  },

  data() {
    return {
      dialog: false,
      dialogUserProfile: false,
      switchEmailNotification: true,
      showPassword: null,
      showPasswordConfirm: null,
      userEmail: null,
      password: null,
      passwordConfirm: null,
      error: false,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarColor: 'info',
      items: [
        {
          icon: 'settings',
          href: '#',
          title: 'Cá nhân',
          click: () => {
            const vm = this;
            vm.dialogUserProfile = true;
          }
        },
        {
          icon: 'exit_to_app',
          href: '#',
          title: 'Đăng xuất',
          click: () => {
            const vm = this;
            vm.$store
              .dispatch("logout")
              .then(() => {
                  location.reload(true)
              });
          }
        }
      ],
      notifications:
      [
      ]
    }
  },
  methods: {
    toggleNavigationBar() {
      const vm = this;
      vm.$eventBus.$emit('toggleNavigationBar');
    },
    goToAllAlarmPage() {
      this.$router.push({ name: 'AllAlarm' });
    }
  }
};
</script>

<style>
  .v-list--two-line.v-list--dense .v-list__tile {
    height: 46px;
  }
</style>

<style lang="scss">
  .custom-template {
    display: flex;
    color: #444444;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 3px;
    margin-bottom: 0;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    &, & > div {
      box-sizing: border-box;
    }
    // background: #E8F9F0;
    // border: 2px solid #D0F2E1;
    border-width: 2px;
    border-style: solid;
    .custom-template-icon {
      // flex: 0 1 auto;
      color: #FFFFFF;
      // font-size: 32px;
      padding: 0 10px;
    }
    .custom-template-close {
      flex: 0 1 auto;
      padding: 0 20px;
      font-size: 16px;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1.0;
      }
    }
    .custom-template-content {
      padding: 6px;
      flex: 1 0 auto;
      .custom-template-title {
        letter-spacing: 1px;
        // text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
  .v-fade-right-enter-active,
  .v-fade-right-leave-active,
  .v-fade-right-move {
    transition: all .5s;
  }
  .v-fade-right-enter,
  .v-fade-right-leave-to {
    opacity: 0.3;
    transform: translateY(-30px);
  }

</style>
