<template>
   <v-menu offset-y>
   <template v-slot:activator="{ on }">
      <!-- class="pa-0 ma-0" small -->
      <div
         style="height:20px;width:20px;border:1px solid #333333;cursor:pointer;"
         :style="{
            'background-color': value
         }"
         tile text
         v-on="on"
      >
      </div>
   </template>
   <v-color-picker :value="value" @input='onColorChanged' show-swatches 
      hide-inputs
      hide-mode-switch
      hide-canvas 
      mode="hexa"></v-color-picker>
</v-menu>
</template>

<script>
export default {
   props:['value'],
   data () {
      return {

      }
   },
   methods: {
      onColorChanged(newColor){
         this.$emit('input', newColor)
      }
   }
}
</script>
