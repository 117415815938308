<template>
  <v-navigation-drawer
      light
      app
      v-model="displayMode"
      style="padding-top:4px;margin-top:58px;"
      v-bind:style="{backgroundColor: $root.themeColor, color: $root.themeTextColor}"
      v-on:input="$emit('input', displayMode)"
      >
   <v-list>
      <template
         v-for="item in items"
         >
         <template v-if="item.system==$root.curSystem">
            <!-- :prepend-icon="item.icon" -->
            <v-list-item-group :key="item.name" color="primary">
               <v-list-group v-if="1==2 && item.childs && item.childs.length>0"
                  :key="item.name"
                  no-action
                  >
                     <template v-slot:activator>
                        <v-list-item-content style="background-color:#FF000000;padding: 2px 0px;">
                           <v-list-item-title 
                              style="font-size:14px;line-height:20px;"
                              class="cursor-pointer"
                              :class="[{'active-tile': selectedIndex === item.index}]"
                              >
                              <img
                                 height="48"
                                 width="28"
                                 style="object-fit:contain;"
                                 :src="item.img"
                                 />
                              <div style="display:inline-block;margin-left:6px;vertical-align:top;line-height:48px;height:48px;">
                                 {{item.displayName}}
                              </div>
                           </v-list-item-title>
                        </v-list-item-content>
                     </template>
                     <v-list-item-group>
                        <v-list-item
                           v-for="childPage in item.childs"
                           :key="childPage.name"
                           @click="changeRoute(childPage.name, childPage.index, childPage.selectedChildIndex, childPage.displayName)"
                        >
                           <v-list-item-content>
                              <v-list-item-title 
                                    style="font-size:13px;padding-left:22px;"
                                    class="cursor-pointer"
                                    :class="[{'active-child': selectedChildIndex === childPage.selectedChildIndex}]"
                                 >
                                 <v-icon style="font-size:10px;margin-right:4px;">brightness_1</v-icon>
                                 {{childPage.displayName}}
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                     </v-list-item-group>
               </v-list-group>
               <v-list-item
                  :key="item.name"
                  :to="item.to"
               >
                  <v-list-item-icon>
                     <v-icon>{{item.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                     <v-list-item-title 
                        style="font-size:16px;"
                        class="cursor-pointer"
                        :class="[{'active-tile': selectedIndex === item.index}]"
                        v-text="item.displayName">
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </v-list-item-group>
            <!--  @click="changeRoute(item.name, item.index, 0, item.displayName)" -->
         </template>
      </template>
   </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
        type: Boolean,
        required: false,
        default: true
    }
  },
  watch: {
  },
  data() {
    return {
      role: 'Admin',
      displayMode : false,
      mini: true,
      navbar_logo: '/imgs/logo_cp.png',
      selectedIndex: 1,
      selectedChildIndex: 1,
      items: require('../../mkdata/navigator').navigators,
      admins: [
        ['Management', 'people_outline'],
        ['Settings', 'settings']
      ]
    }
  },
  mounted() {
    const vm = this;
    this.selectedIndex = 1
    this.items.forEach(item => {
      if(item.name==vm.$route.name){
        this.selectedIndex = item.index
      }
      if(item.childs){
        item.childs.forEach(child => {
          if(child.name==vm.$route.name){
            this.selectedIndex = child.index
            this.selectedChildIndex = child.selectedChildIndex
          }
        })
      }
    })

  },
  methods: {
      goto(routeName) {
         this.displayMode = false
        this.$router.push({ name: routeName });
      },
      changeRoute(routeName, selectedIndex, childSelectedIndex, titleName) {
         this.displayMode = false
         const vm = this;
         vm.selectedIndex = selectedIndex;
         vm.selectedChildIndex = childSelectedIndex
         vm.$root.curTitle = titleName
         try {
            console.log("push " + routeName)
            vm.$router.push({ name: routeName })
         } catch(err) {console.log(err)}
      },
      toggleDisplay() {
         this.displayMode = !this.displayMode
      }
  },
  created() {
    const vm = this;
    vm.$eventBus.$on('toggleNavigationBar', this.toggleDisplay);
  },
  destroyed() {
    const vm = this;
    vm.$eventBus.$off('toggleNavigationBar', this.toggleDisplay);
  },
}
</script>

<style>
  .active-tile {}
  .active-child {
    font-weight: 600;
    color: "#FFFFFF44"
  }
  .cursor-pointer {
     cursor: pointer;
  }
</style>