<template>
   <div class="point-list-item" @click="toggleSelect">
      <v-icon v-if="source && source.picked" 
         style="font-size:18px;margin-right:4px;">check_circle</v-icon>
      <div v-else style="display:inline-block;width:22px;"></div>
      {{source.reportName}}
   </div>
</template>

<script>
export default {
   props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
     toggleSelect() {
         let self = this
         this.$eventBus.$emit('point-list-item-toggle', this.source.reportName);
         setTimeout(() => {
            self.$forceUpdate()
         }, 100)
     }
  }
}
</script>

<style scoped>
   .point-list-item {
      font-size: 13px;
      width: 100%;
   }
</style>