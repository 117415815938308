<template>
   <v-menu offset-y 
      v-model="menu"
      :close-on-content-click='false'>
      <template v-slot:activator="{ on }">
         <v-btn
            color="#B2B2B2" dark depressed 
            style="width:34px;height:38px;margin-top:1px;border-radius: 4px 0 0 4px;"
            v-on="on" small
         > 
            <v-icon>search</v-icon>
         </v-btn>
      </template>

      <v-container class="pa-0" style="font-size:16px;background-color:white;width:660px;">
         <v-row style="pa-0 pl-1">
            
            <v-col cols="5" class="pl-3 pr-0 pb-0 pt-2" style="">
                  <v-card flat class="pa-0 ma-0"
                     style="border-bottom: 1px solid #CCCCCC;">
                     <v-card-title 
                        class="py-0"
                        style="color:#111111;font-weight:700;font-size:14px;margin: 2px;">
                        Devices: ({{subDevList.length}})
                        <v-spacer/>
                        <v-btn text color="primary"
                           style="min-width:40px;"
                           class="text-none px-0 mx-1 my-0"
                           @click="selectNoneDev">
                           None
                        </v-btn>
                        <v-btn text color="primary"
                           style="min-width:40px;"
                           class="text-none px-0 mx-1 my-0"
                           @click="selectAllDev">
                           All
                        </v-btn>
                     </v-card-title>
                  </v-card>
                  <v-list dense 
                     style="max-height:380px;overflow-y:auto;">
                     <v-list-item-group
                        multiple
                        v-model="selectedGroup">
                        <template v-for="(item, i) in subDevList">
                           <v-list-item dense
                              :key="`item-${i}`"
                              :value="item"
                              active-class="blue--text"
                              style="height:16px;max-height:16px;padding-left:8px;"
                           >  
                              <template v-slot:default="{active }">
                                 <v-list-item-content
                                    style="margin:0px !important;padding:0px !important;">
                                    <v-list-item-title style="font-size:14px;">
                                       <v-icon v-if="active" 
                                          style="font-size:18px;margin-right:4px;">check_circle</v-icon>
                                       <div v-else style="display:inline-block;width:22px;"></div>
                                       {{item}}
                                    </v-list-item-title>
                                 </v-list-item-content>
                              </template>
                           </v-list-item>
                        </template>
                     </v-list-item-group>
                  </v-list>

            </v-col>

            <v-col class="pr-4 pl-1 pt-1" cols="7" style="" v-if="displayPoints">
               <v-card flat class="ma-0 pa-0">
                  <v-card-title style="color:#111111;font-weight:700;font-size:16px;margin:0 0 4px 0;padding:0px 8px;">
                     Point List:
                     <v-spacer/>
                     <v-btn text 
                        v-if="displayPoints && displayPoints.length>0"
                        color="primary" class="text-none"
                        @click="selectNonePoint">
                        Select None
                     </v-btn>
                     <v-btn text 
                        v-if="displayPoints && displayPoints.length>0"
                        color="primary" class="text-none"
                        @click="selectAllPoint">
                        Select All
                     </v-btn>
                     <!-- @click="$emit('pointsPicked', displayPoints);menu=false;" -->
                  </v-card-title>
               </v-card>

               
               <div>
                  <div>
                     <v-text-field
                        v-model="textFilter"
                        label=""
                        outlined dense
                        prepend-inner-icon="search"
                        clearable single-line hide-details
                        class="mx-1 mb-1"
                     ></v-text-field>
                  </div>
                  <virtual-list class="list"
                     v-if="showPointList"
                     :data-key="'reportName'"
                     :data-sources="displayPoints"
                     :data-component="itemComponent"
                     :item-class="'list-item-fixed'"
                  />
               </div>
            </v-col>

            <v-col cols="12">
               <v-card flat>
                  <v-card-actions>
                     <v-spacer/>
                     <v-btn text class="text-nont" @click="cancelSelect">
                        Cancel
                     </v-btn>
                     <v-btn text class="text-nont" @click="applySelected">
                        Ok
                     </v-btn>
                  </v-card-actions>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </v-menu>
</template>

<script>
import PointListItem from './PointListItem'
export default {
   props:['subDevList'],
   computed: {
      displayPoints: function(){
         if(!this.points || !this.selectedGroup) return []
         return this.points.filter(p => {
            if(!p.subDev || p.subDev.length==0) 
            {
               if(this.selectedGroup == 'Blank') {
                  return true
               } else {
                  return false
               }
            } else {
               return p.subDev 
                  && this.selectedGroup.includes(p.subDev) 
                  && (!this.textFilter || p.reportName.includes(this.textFilter))
            }
         })
      }
   },
   data () {
      return {
         itemComponent: PointListItem,
         selectedGroup: null,
         selectedPoints: [],
         menu: false,
         points: [],
         showPointList: true,
         textFilter: '',
      }
   },
   mounted() {
      this.$nextTick(() => {
         this.fetchPointList()
      })
   },
   methods: {
      applySelected () {
         if(this.displayPoints && this.displayPoints.length>0){
            this.selectedPoints = this.displayPoints.filter(p => p.picked)
            this.$emit('pointsPicked', this.selectedPoints)
         }
         this.menu=false
      },
      cancelSelect () {
         this.menu=false
      },
      selectAllDev() {
         this.selectedGroup = this.subDevList
      },
      selectNoneDev() {
         this.selectedGroup = []
         
      },
      selectAllPoint() {
         this.displayPoints.forEach(p => p.picked = true)
         let self = this
         self.showPointList = false
         setTimeout(() => {
            self.showPointList = true
         },100)

      },
      selectNonePoint() {
         this.displayPoints.forEach(p => p.picked = false)
         this.selectedPoints = []
         let self = this
         self.showPointList = false
         setTimeout(() => {
            self.showPointList = true
         },100)
      },
      fetchPointList() {
         let self = this
         let fetchPath = this.$root.api + "listPoints"
         self.axios.post(fetchPath, {subDevs: this.subDevList}, {headers: { "x-auth": self.token }}).then(res => {
            if(res.data.status=='OK'){
               self.points = res.data.content
            } else {
               alert(res.data.message)
            }
         })
      },
   },
   created () {
      this.$eventBus.$on('point-list-item-toggle', (reportName) => {
         console.log(reportName)
         const targetItem = this.displayPoints.find((point) => point.reportName === reportName)
         if (targetItem) {
            if(targetItem.picked)
               targetItem.picked = false
            else
               targetItem.picked = true
         }
      })
   },
   destroyed() {
      this.$eventBus.$off('point-list-item-toggle')
   }
}
</script>

<style>
   .list {
      width: 100%;
      height: 340px;
      overflow-y: auto;
   }
   .pointPicker-pointItem {
      padding:0px 12px;
      font-size:14px;
      cursor:pointer;
      font-weight:400;
      width:100%;
      height:30px;
      line-height:30px;
      border-bottom: 1px solid #CCCCCC44;
   }
   .pointPicker-pointItem:hover {
      background-color: #E3F2FD;
      font-weight:600;
      color: #1565C0;
   }

   .list-item-fixed {
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: 30px;
    border-bottom: 1px solid;
    border-color: lightgray;
  }
</style>