<template>
   <v-card>
      <v-card-title 
         class="py-2"
         style="background-color:#0277BD; color:white;">
            GS Thiết Bị
         <v-spacer/>
      </v-card-title>
      <v-card-text class="pa-4" style="font-size:16px;">
         <div>
            <v-text-field
               outlined dense hide-details
               style="max-width:260px;"
               class="mb-4"
               v-model="devCode"
            ></v-text-field>
         </div>
         <v-btn @click="startRemote" class="mr-4" color="primary" dark>
            Run
         </v-btn>
         <v-btn @click="stopRemote" class="mr-4" color="red" dark>
            Stop
         </v-btn>
         <div v-if="retData" 
            class="mt-2 pa-4 text-center" 
            style="border-radius:4px;background-color:#333333;color:white;line-height:2;">
            {{retData}}
         </div>
      </v-card-text>
   </v-card>
</template>

<script>
export default {
   computed: {
   },
   data () {
      return {
         retData: '',
         devCode: 'PONO-000',
         socketAskKey: new Date().getTime()
      }
   },
   sockets: {
      initRemoteResult: function(payload){
         console.log("initRemoteResult")
         console.log(payload)
         this.retData = payload.url
      },
      stopRemoteResult: function(){
         console.log("stopRemoteResult")
         this.retData = ''
      }
   },
   methods: {
      startRemote () {
         this.$socket.emit('initRemote', 
         { 
            devCode: this.devCode, //this.$root.devCode,
            askKey: this.socketAskKey,
            options: {
               proto: 'tcp',
               addr: 22,
               authtoken: '7P1ApXf79jpGGCGYA2zCt_3uV5NZf7fo9dEEGykXGrk'
            }
         })
      },
      stopRemote () {
         this.$socket.emit('stopRemote', 
         { 
            devCode: this.devCode, //this.$root.devCode,
            askKey: this.socketAskKey
         })
      },
   }
}
</script>