import Toolbar from "../components/core/Toolbar.vue";
import ToolbarNavigator from "../components/core/ToolbarNavigator.vue";
import Navigation from "../components/core/NavigationDrawer.vue";
import NotiSetting from "../components/atom/NotiSetting.vue";
import RemoteIt from "../components/atom/RemoteIt.vue";

import DatePicker from "../components/atom/DatePicker.vue";
import ProfileManager from "../components/atom/ProfileManager.vue";
import StringEditorDialog from "../components/atom/StringEditorDialog.vue";
import PointSetter from "../components/atom/PointSetter.vue";
import PointPicker from "../components/atom/PointPicker.vue";
import PointPickerPMS from "../components/atom/PointPickerPMS.vue";
import ParaPicker from "../components/core/ParaPicker.vue";
import RefPicker from "../components/atom/RefPicker.vue";
import PointList from "../components/atom/PointList.vue";
import ReportBuilder from "../components/atom/ReportBuilder.vue";
import ChartBuilder from "../components/atom/ChartBuilder.vue";
import ColorPicker from "../components/atom/ColorPicker.vue";
import BarChartSingle from "../components/atom/BarChartSingle.vue";
import LineChartPoints from "../components/atom/LineChartPoints.vue";

import VirtualList from 'vue-virtual-scroll-list'

function setupComponents(Vue) {
  Vue.component("noti-setting", NotiSetting);
  Vue.component("remote-it", RemoteIt);
  Vue.component("toolbar", Toolbar);
  Vue.component("navigation", Navigation);
  Vue.component("toolbar-navigator", ToolbarNavigator);
  Vue.component("color-picker", ColorPicker);

  Vue.component("date-picker", DatePicker);
  Vue.component("string-editor-dialog", StringEditorDialog);
  Vue.component("profile-manager", ProfileManager);
  Vue.component("point-setter", PointSetter);
  Vue.component("point-list", PointList);
  Vue.component("point-picker-pms", PointPickerPMS);
  Vue.component("point-picker", PointPicker);
  Vue.component("para-picker", ParaPicker);
  Vue.component("ref-picker", RefPicker);
  Vue.component("report-builder", ReportBuilder);
  Vue.component("chart-builder", ChartBuilder);
  Vue.component("bar-chart-single", BarChartSingle);
  Vue.component("line-chart-points", LineChartPoints);

  Vue.component("virtual-list", VirtualList);
}

export { setupComponents };
