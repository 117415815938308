import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import VueSocketIO from 'vue-socket.io'
import { setupComponents } from "./config/setup-components";
import VueChart from 'vue-chart-js'
// import VueSvgGauge from 'vue-svg-gauge'

setupComponents(Vue);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueChart)
Vue.use(require('vue-moment'))
// Vue.use(VueSvgGauge)

/*eslint-disable */
let serverHostSocket = "https://otaio.pono.vn"
let serverAPI = "" + coreGetServerHost() + "/"
/*eslint-enable */
// console.log("Server API " + serverAPI)

Vue.use(VueSocketIO, serverHostSocket)

const EventBus = new Vue();
Vue.prototype.$eventBus = EventBus;

// router.beforeEach((to, from, next)=>{
//   // store.dispatch('fetchUserId');
//   if (to.fullPath !== '/login' && (!store.state.userId || !store.state.token) ) {
//     next('/login');
//   } else {
//     next();
//   }
// })

new Vue({
  router,
  store,
  vuetify,
  data: {
    devCode: "PN-TSC-001",
    curSystem: "BMS",
    curTitle: "Nhà Nam",
    mainColor: "#1565C0",
    themeColor: "#FFFFFF",
    themeTextColor: "red",
    pageBackColor: "#FFFFFF",
    pageTextColor: "#666666",
    controlColor: "#BF360CBB",
    fieldsColor: [
      "#0277BD",
      "#795548",
      "#FFA000",
      "#4CAF50",
      "#F44336",
      "#9C27B0",
      "#00BCD4",
      "#FFC107",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#607D8B",
      "#9E9E9E",
      "#E91E63",
      "#F44336",
      "#9C27B0",
    ],
    transparent: true,
    themeBackColor: "transparent",
    themeFlat: true,
    availablePointType: require("../rules").availablePointType,
    parasKeys: "ujihertybfg4fet56ythf433",
    schedulerKeys: "sdjkfnkjdsbgkjdfkjhuif",
    apiClient: serverAPI,
    apiUserCustomerId: "nam-house-21-env",
    apiUser: "https://saasusr.pono.vn",
    apiGate: serverAPI,
    logApi: "http://localhost:3044/",
    api: serverAPI,
  },
  render: (h) => h(App),
}).$mount("#app");
