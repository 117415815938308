<template>
   <v-card flat class="py-0 px-0" style="">
      <v-card-title class="pa-0" style="font-size:16px;font-weight:500;background-color:#EDEDED;border-radius: 4px;">
         <point-picker v-if="!pmsCustom" 
            :subDevList='subDevList'
            :points='sourcePoints'
            @pointPicked="onPointPicked"
            @pointsPicked="onPointsPicked"/>
         <point-picker-pms v-if="pmsCustom" 
            :subDevList='subDevList'
            :points='sourcePoints'
            @pointPicked="onPointPicked"
            @pointsPicked="onPointsPicked"/>
         <div style='margin-left:8px;display:inline-block;'>
            List Of Point ({{value.length}})
         </div>
         <v-spacer/>
         <template v-if="value && value.length>0">
            <v-btn v-if="value.length<200"
               text color="#111111" class="text-none"
               @click="selectAllPoint">
               Select All
            </v-btn>
            <v-btn v-if="value.length<200"
               text color="#111111" class="text-none"
               @click="selectNonePoint">
               Select None
            </v-btn>
            <v-btn 
               text color="red" class="text-none"
               @click="$emit('input', [])">
               Delete All
            </v-btn>
         </template>
         
      </v-card-title>

      <v-card-text v-if="value" class="py-0 px-0" style="">
         <v-list v-if="value.length<200"
            dense class="ma-0 pa-0" 
            style="max-height:260px;background-color:#FAFAFA;overflow-y:auto;">
            <v-list-item-group>
               <template v-for="(point, i) in value">
                  <v-list-item dense
                     :key="`item-${i}`"
                     :value="point"
                     active-class="blue--text"
                     style="height:16px;max-height:16px;padding-left:8px;border-bottom: 1px solid #DEDEDE;"
                  >  
                     <v-list-item-content :style="{color:point.color}"
                        style="margin:0px !important;padding:0px !important;">
                        <v-list-item-title style="font-size:16px;" @click="toggleSelect(point)">
                           <v-icon :color="point.color">
                              {{point.active?'check_box':'check_box_outline_blank'}}
                           </v-icon>
                           <span style="marin-left:6px;margin-right:6px;font-weight:700;font-size:14px;">
                              {{point.reportName}}
                           </span>
                        </v-list-item-title>
                     </v-list-item-content>

                     <v-list-item-action>
                        <v-btn icon @click="removePoint(point.reportName)">
                           <v-icon color="red">clear</v-icon>
                        </v-btn>
                     </v-list-item-action>
                  </v-list-item>
               </template>
            </v-list-item-group>
         </v-list>
         <div v-else class="mt-2 text-center">
            {{value.length}} points selected
         </div>
      </v-card-text>
   </v-card>
</template>

<script>
export default {
   props: ['sourcePoints', 'value', 'subDevList', 'pmsCustom'],
   watch: {
      value: function (){
         this.points = this.value
      }
   },
   data () {
      return {
         points: [],
         chartColors: [
            '#F50057',
            '#5E35B1',
            '#0097A7',
            '#4CAF50',
            '#9E9D24',
            '#FF9800',
            '#795548',
            '#D84315',
            '#607D8B',
            '#757575',
            '#303F9F',
            '#880E4F',
            '#B71C1C'],
      }
   },
   methods: {
      notifyChangeParent () {
         this.$emit('input', this.value)
      },
      onPointPicked (point) {
         let tmpPoints = JSON.parse(JSON.stringify(this.value))
         if(this.value && !this.value.find(p=>p.reportName == point.reportName)){
            this.applyPointIndicate(tmpPoints, point)
            tmpPoints.push(point)
         }
         this.$emit('input', tmpPoints)
      },
      selectAllPoint(){
         this.value.forEach(p=>p.active=true)
         this.$emit('notifySelectChange')
         this.$forceUpdate()
      },
      selectNonePoint(){
         this.value.forEach(p=>p.active=false)
         this.$emit('notifySelectChange')
         this.$forceUpdate()
      },
      toggleSelect (point) {
         if(point.active) {
            point.active = false
         } else {
            point.active = true
         }
         this.$emit('notifySelectChange')
         this.$forceUpdate()
      },
      onPointsPicked (points) {
         if(!points || points.length<=0) return
         let tmpPoints = JSON.parse(JSON.stringify(this.value))
         points.forEach(point => {
            if(this.value && !this.value.find(p=>p.reportName == point.reportName)){
               this.applyPointIndicate(tmpPoints, point)
               tmpPoints.push(point)
            }
         })
         
         this.$emit('input', tmpPoints)
      },
      applyPointIndicate(curPoints, point){
         point.active = true
         if(curPoints.length+1<this.chartColors.length){
            point.color = this.chartColors[curPoints.length]
         } else {
            point.color="#222222"
         }
      },
      removePoint (reportName) {
         let tmpPoints = JSON.parse(JSON.stringify(this.value))
         tmpPoints = tmpPoints.filter(p => p.reportName != reportName)
         this.$emit('input', tmpPoints)
      }
   },
   mounted() {
      this.$nextTick(()=>{
         this.points = this.value
      })
   },
}
</script>

<style>
   
</style>