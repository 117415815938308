<template>
   <div>
      <v-select
         :items="modes"
         v-model="activeMode"
         item-text ="label"
         item-value ="value"
         label="" color="primary"
         style="display:inline-block;width:75px;max-width:75px;border-width: 1.5px;font-size:14px;"
         outlined dense
      >
         <template v-slot:selection="{ item }">
            <div style="text-align:left;padding-left:8px;width:100%;">
               {{item.label}}
            </div>
         </template>
      </v-select>
      <para-picker 
         v-if="activeMode && activeMode=='para'"
         class="fieldValue"
         style="max-width:220px;width:220px;display:inline-block;margin-left:4px;"
         :paras = "paras"
         @on-picked="onParaPicked(state, ...arguments)"
         v-model="activePara"/>
      <div v-if="activeMode && activeMode=='point'"
         style="display:inline-block;min-width:220px;vertical-align:top;margin-left:4px;">
         <div>
            <div style="display:inline-block;vertical-align:top;">
               <point-picker
                  :points='points' @pointPicked="onPointPicked(state, ...arguments)"/>
            </div>
            <input type="text" 
               style="font-size:13px;width:168px;font-weight:600;display:inline-block;vertical-align:top;;background-color:#F6F6F6;border:1px solid #AAAAAA;border-radius:0 4px 4px 0;height:38px;line-height:38px;padding: 0px 4px;margin-top:1px;"
               v-model="activePoint"
               @input="activePointChange">
            <!--
            <div style="font-size:13px;width:168px;font-weight:600;display:inline-block;vertical-align:top;;background-color:#F6F6F6;border:1px solid #AAAAAA;border-radius:0 4px 4px 0;height:39px;line-height:39px;padding: 0px 4px;">
               {{activePoint?activePoint:''}}
            </div>
            -->
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: ['paras', 'state'],
   watch: {
      'state.threshold': {
         handler: function(){
            console.log('on watch state.threshold')
            this.activeMode = this.state.thresMode
            if(this.state.thresMode=='para'){
               this.activePara = this.state.threshold
            }
            if(this.state.thresMode=='point'){
               if(this.state.threshold)
                  this.activePoint = this.state.threshold
            }
         },
         deep: true
      },
      'state.thresMode': {
         handler: function(){
            console.log('on watch state.thresMode')
            this.activeMode = this.state.thresMode
            if(this.state.thresMode=='para'){
               this.activePara = this.state.threshold
            }
            if(this.state.thresMode=='point'){
               if(this.state.threshold)
                  this.activePoint = this.state.threshold
            }
         },
         deep: true
      }
   },
   data () {
      return {
         points: require('@/mkdata/modal_pms_points.js').pmsPoints,
         activeMode: 'para',
         modes: [
            {label: "Pr", value: 'para'},
            {label: "Pt", value: 'point'},
         ],
         activePara: '',
         activePoint: '',
      }
   },
   methods: {
      onParaPicked (state, para) {
         this.$emit('on-para-picked', para)
      },
      onPointPicked (state, point) {
         // this.$emit('on-point-picked', point)
         this.activePoint = point.reportName
      },
      activePointChange () {
         this.$emit('on-point-picked', {reportName: this.activePoint})
         console.log(this.activePoint)
      }
   },
   mounted() {
      this.$nextTick(()=>{
         console.log('on mounted')
         if(this.state){
            this.activeMode = this.state.thresMode
            if(this.state.thresMode=='para'){
               this.activePara = this.state.threshold
            }
            if(this.state.thresMode=='point'){
               if(this.state.threshold)
                  this.activePoint = this.state.threshold
            }
         }
         console.log(this.activePara)
      })
   },
}
</script>

<style>
   
</style>