module.exports = {
   availablePointType: [
       {
           label: "Boolean",
           value: "PLC_BOOL",
           short: "BOOL",
           short1: 'B',
           info: "Type boolean, 1 bit",
           color: '#CE3746'
       },
       {
           label: "Short",
           value: "PLC_INT",
           short: "INT",
           short1: "I",
           info: "Equal C# type short, and PLC type INT, 2bytes",
           color: '#05819C'
       },
       {
           label: "Integer",
           value: "PLC_DINT",
           short: "DINT",
           short1: "D",
           info: "Equal C# type int, and PLC type DINT, 4bytes",
           color: '#05819C'
       },
       {
           label: "Long",
           value: "PLC_LINT",
           short: "LINT",
           short1: "L",
           info: "Equal C# type long, and PLC type LINT, 8bytes",
           color: '#05819C'
       },
       {
           label: "Float",
           value: "PLC_REAL",
           short: "REAL",
           short1: "F",
           info: "Equal C# type float, and PLC type REAL, 4bytes",
           color: '#C37331'
       },
       {
           label: "Double",
           value: "PLC_LREAL",
           short: "DOUBLE",
           short1: "R",
           info: "Equal C# type double, and PLC type LREAL, 8bytes",
           color: '#C37331'
       }
   ],
   availableStateType: [
       {
           label: "State Value Equal",
           value: "equal"
       },
       {
           label: "State Value Greater",
           value: "greater"
       },
       {
           label: "State Value Lesser",
           value: "lesser"
       }
   ]
}