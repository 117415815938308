<template>
   <v-card>
      <v-card-title 
         class="py-2"
         style="background-color:#0277BD; color:white;">
         Cài đặt thông báo
         <v-spacer/>
      </v-card-title>
      <v-card-text class="pa-4" style="font-size:16px;">
         <div v-if="!notiSupported" style="font-size:16px;line-height:2;color:#444444;">
            <i>Phiên bản Browser này không hổ trợ nhận thông báo qua web.</i>
         </div>
         <div v-else>
            <div style="font-size:16px;line-height:2;">
               Trạng thái: <b>{{notiGranted?"Cho phép thông báo":"Không nhận thông báo"}}</b>
            </div>
            <div v-if="!notiGranted">
               <v-btn dark color="primary"
                  @click="turnOnNotify(true)"
                  class="mt-1 text-none">
                  <v-icon class="mr-2">
                     notifications
                  </v-icon>
                  Cho phép nhận thông báo
               </v-btn>
            </div>
         </div>
      </v-card-text>
   </v-card>
</template>

<script>
export default {
   computed: {
      notiSupported: function () {
         return ("Notification" in window)
      },
      notiGranted : function () {
         return ("Notification" in window) && Notification.permission == 'granted'
      },
      notiDenied : function () {
         return Notification.permission == 'denied'
      }
   },
   methods: {
      turnOnNotify(value){
         if(value) {
            Notification.requestPermission().then((permission) => {
               if (permission === 'granted') {
                  alert('Notification permission granted.')
               } else {
                  alert('Unable to get permission to notify.')
               }
            })
         }
      }
   }
}
</script>