<template>
  <v-app>
    <!--  #EF5350 #264B77 -->
    <v-app-bar app clipped-left color="#01579B" dark dense height="58">

      <v-btn
        icon
        @click="toggleNavigationBar"
        v-if="1==1 && token"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <div class="d-flex align-center" style="">
        <v-btn
              v-if="1==2 && $root.curSystem && $root.curSystem.length>0 && token"
              color="white" class="px-2 ml-1"
              style="text-transform:none;font-weight:400;"
              text
              @click="goto('Home')"
            >
              <v-icon>home</v-icon>
            </v-btn>
      </div>
      <toolbar-navigator 
        v-if="1==2 && $vuetify.breakpoint.mdAndUp 
        && userId && token" :system='$root.curSystem' />
      <v-spacer></v-spacer>
      <span style="color:white;font-size:20px;font-weight:700;"
        class="text-uppercase">
        {{$root.curTitle}}
      </span>
      <v-spacer></v-spacer>

      <!-- && $root.curSystem=='BMS' -->
      <v-menu v-if="1==2 && userId && token"
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon text color="black" slot="activator" v-on="on">
            <v-badge
              color="red"
              overlap
              :value="notifications.filter((x) => x.isActive).length > 0"
              :content="notifications.filter((x) => x.isActive).length"
            >
              <v-icon color="white" medium>notifications</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="ma-0 pa-0">
          <v-card-title class="ma-0 px-3 py-0 pt-1">
            <v-spacer />
            <v-btn
              text
              style="text-transform:none;"
              color="primary"
              @click="goto('BMSEvents')"
            >
              Alarm History
            </v-btn>
            <v-spacer />
          </v-card-title>
          <v-card-text class="ma-0 pa-0" style="max-height:300px;min-width:320px;">
            <div v-if="notiBlackList && notiBlackList.length>0"
              class="px-4"
              style="font-size:14px;color:#AFB42B;border-bottom:1px solid #AFB42B;">
              Mute list
            </div>
            <v-list v-if="notiBlackList && notiBlackList.length>0"
              dense light style="border-radius:0px;">
              <v-list-item-group color="primary">
                <template v-for="(item, index) in notiBlackList">
                  <v-list-item :key="index + 1000">
                    <v-list-item-icon>
                      <v-icon color="#AFB42B" style="color:#AFB42B;" class="mt-3">volume_off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title style="color:#AFB42B;"
                        class="seenNotify"
                        v-text="item">
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn text small icon @click="removeNotiBlock(item)">
                        <v-icon color="#AFB42B">
                          clear
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="index + 1100" :inset="false" color="#AFB42B"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
            <v-list dense light style="border-radius:0px;">
              <v-list-item-group color="primary">
                <template v-for="(item, index) in notifications">
                  <v-list-item :key="index + 1000">
                    <v-list-item-icon>
                      <v-icon v-if="item.icon" :color="item.color">{{
                        item.icon
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.content"
                        v-bind:class="{
                          activeNotify: item.isActive,
                          seenNotify: !item.isActive,
                        }"
                      >
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn text small icon @click="addNotiBlock(item.desc)">
                        <v-icon color="red">
                          volume_off
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="index + 1100" :inset="false"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <!--
          <v-card-actions class="ma-0 pa-0 pb-2">
            <v-spacer />
            <v-btn
              text
              style="text-transform:none;"
              color="primary"
              @click="notifications.forEach((n) => (n.isActive = false))"
            >
              Mark All Seen
            </v-btn>
            <v-spacer />
          </v-card-actions>
          -->
        </v-card>
      </v-menu>

      <v-menu
        v-if="1==1 && token"
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
        class="mr-3"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            slot="activator"
            color="white"
            v-on="on"
            style="margin:0px;text-transform:none;font-size:14px;width:100px;min-width:30px;padding:0px;"
            :ripple="false"
          >
            <div
              style="overflow: hidden;margin:0px;padding:0px;width:100%;height:auto;"
            >
              <span v-if="getFullName"> {{ getFullName }}</span>
              <span v-else> <v-icon>mdi-account-circle</v-icon></span>
            </div>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="cursor-pointer"
                @click="goto('UserProfile')"
              >
                <v-icon class="mr-2">mdi-account-circle</v-icon>Hồ sơ cá nhân
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--
          <v-list-item
            v-if="getLevel == 'ninAnd033' || getLevel == 'ASnind010'"
          >
            <v-list-item-content>
              <v-list-item-title
                class="cursor-pointer"
                @click="goto('SetupUsers')"
              >
                <v-icon class="mr-2">mdi-account-settings</v-icon>Setup Users
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="cursor-pointer" @click="logoutUser">
                <v-icon class="mr-2">mdi-logout</v-icon>Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text
        v-if="1==2 && !token"
        style="text-transform: capitalize;"
        @click="goto('Login')"
      >
        <v-icon class="mr-2" v-if="1==2">mdi-login</v-icon> Login
      </v-btn>
      <point-setter v-model='setterDialog' :point="setterPoint"/>
    </v-app-bar>

    
    <v-content>
      <router-view />
    </v-content>
    <navigation v-model="drawer" v-if="token || $vuetify.breakpoint.smAndDown" />
  </v-app>
</template>

<script>
  import { mapMutations, mapGetters } from "vuex";
  const moment = require("moment");
  export default {
    name: "App",
    components: {
      // LoginDialog,
    },
    computed: {
      ...mapGetters(["mute","token", "getFullName", "getLevel", "userId", "allowControl", "notiBlackList"]),
      muted () {
        return this.$store.state.mute == '1';
      }
    },
    /*
    watch: {
      muted: function() {console.log('muted changed ' + this.muted)}
    },
    */
    sockets: {
        connect: function () {
          console.log("IO Connected")
          this.$socket.emit("registerIsHmi", {})
        },
        currentAlarm: function (alarms) {
          this.updateAllNotification(alarms)
        }
    },
    data: () => ({
      drawer: true,
      notifications: [],
      setterDialog: false,
      setterPoint: null,
      kindOfAlarms: {
        normal: {color: 'blue', icon: 'mdi-information-outline'},
        warning: {color: 'orange', icon: 'mdi-alert-outline'},
        error: {color: 'red', icon: 'mdi-close-octagon-outline'},
        danger: {color: 'purple', icon: 'mdi-close-octagon-outline'},
      }
    }),
    methods: {
      ...mapMutations(["setIsLogin"]),
      toggleMute(){
        if (this.mute && this.mute=='1'){
          this.$store.dispatch("unmuteAlarm")
        } else {
          this.$store.dispatch("muteAlarm")
        }
      },
      showPointControl (point) {
        this.setterPoint = point
        this.setterDialog = true
      },
      toggleNavigationBar() {
        this.$eventBus.$emit("toggleNavigationBar");
      },
      goto(routeName) {
        this.$router.push({ name: routeName });
      },
      logoutUser() {
        // Set Log
        this.$store.dispatch("setLogUser", {
          desc: `User '${this.getFullName}' logout`,
          userId: this.userId,
          manName: this.getFullName,
          tags: "logout",
          date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        });
        
        this.$store.dispatch("logout").then(() => {
          this.goto("Login");
        });
      },
      updateAllNotification (alarms) {
        this.notifications = []
        if(alarms){
          // console.log(alarms)
          alarms.forEach(alarm => {
            this.notifications.push({
              icon: this.kindOfAlarms[alarm.alarmLevel].icon,
              desc: alarm.desc,
              content: alarm.desc + ((alarm.value!=true&&alarm.value!=false)?(", " + alarm.value + ' '+ alarm.unit):''),
              color: this.kindOfAlarms[alarm.alarmLevel].color,
              alarmLevel: alarm.alarmLevel,
              isActive: true,
            })
          })
        }
      },
      addNotiBlock(name){
        this.$store.dispatch("addNotiToBlackList", {
          name: name
        }).then(res=>{
          console.log(res.status)
        })
      },
      removeNotiBlock(name){
        this.$store.dispatch("removeNotiFromBlackList", {
          name: name
        }).then(res=>{
          console.log(res.status)
        })
      }
    },
    created() {
      const vm = this;
      vm.$eventBus.$on('showPointSetter', this.showPointControl);
    },
    destroyed() {
      const vm = this;
      vm.$eventBus.$off('showPointSetter', this.showPointControl);
    }
  };
</script>

<style>
  .activeNotify {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .seenNotify {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
</style>
