<template>
   <div v-if="value">
      <v-dialog persistent v-model="showDialog" width="640" >
         <v-card>
            <v-card-title
               color="primary" class="py-1 pr-2"
               style="background-color:#01579B;color:white;"
               primary-title
            >
               Point Control
               <v-spacer/>
               <v-btn icon
                  color="white"
                  @click="closeDialog">
                  <v-icon>close</v-icon>
               </v-btn>
            </v-card-title>

            <v-card-text class="pt-4 pb-4" style="background-color:#F6F6F6;min-height:300px;">
               <v-progress-linear v-if="showFetching"
                  indeterminate
                  color="primary"
               ></v-progress-linear>
               <v-container v-if="point && !showFetching"
                  fluid style="font-size:18px;">

                  <v-row align="center" v-if="setterPoint.name">
                     <v-col cols="4" style="font-size:18px;">
                        Name
                     </v-col>
                     <v-col cols="8" class="ma-0 pa-1" style="font-size:18px;font-weight:400;">
                        {{setterPoint.name}}
                     </v-col>
                  </v-row>

                  <v-row align="center" v-if="setterPoint.reportName">
                     <v-col cols="4" style="font-size:18px;">
                        ReportName
                     </v-col>
                     <v-col cols="8" class="ma-0 pa-1" style="font-size:18px;font-weight:500;">
                        {{setterPoint.reportName}}
                     </v-col>
                  </v-row>

                  <v-row align="center" class="mt-2" v-if="hasMode">
                     <v-col cols="4" style="font-size:18px;">
                        Mode
                     </v-col>
                     <v-col cols="8" class="ma-0 pa-1" style="font-size:20px;">
                        <v-select class="ma-0" color="#444444"
                           v-model="currentMode"
                           :items="supportModes"
                           item-text="label"
                           item-value="value"
                           label=""
                           persistent-hint
                           return-object
                           single-line hide-details
                           outlined dense
                           :style="{'background-color': currentMode.bgcolor}"
                        ></v-select>
                     </v-col>
                  </v-row>

                  <v-row  v-if="isBoolPoint"
                     align="center" class="mt-2">
                     <v-col cols="4" style="font-size:18px;">
                        Value
                     </v-col>
                     <v-col cols="8" class="ma-0 pa-1" style="font-size:20px;">
                        <!-- 
                           :disabled="currentMode.value=='auto' && hasMode" -->
                        <v-select class="ma-0" color="#444444"
                           v-model="setterPoint.value"
                           :items="boolValues"
                           item-text="label"
                           item-value="value"
                           label=""
                           persistent-hint
                           single-line hide-details
                           outlined dense
                        ></v-select>
                     </v-col>
                  </v-row>

                  <v-row v-else
                     align="center" class="mt-2">
                     <v-col cols="4" style="font-size:18px;">
                        Value
                     </v-col>
                     <v-col cols="8" class="ma-0 pa-1" style="font-size:20px;">

                        <div style="width:100%;height:40px;padding:0px;border: 1.5px solid #CCCCCC;border-radius: 4px;background-color:#FFFFFF;">
                           <!--
                           <input v-if="hasMode && currentMode.value=='auto'"
                              type="text" v-model="setterPoint.value"
                              disabled
                              style="background-color:#F0F0F0;height:39px;width:65%;line-height:38px;margin-top:-1px;text-align:right;border-radius:4px 0px 0px 4px;font-size:18px;font-weight:600;color:#444444;padding:0px 8px;"
                              >
                              -->
                           <input
                              type="text" v-model="setterPoint.value"
                              style="height:39px;width:65%;line-height:38px;margin-top:-1px;text-align:right;border-radius:4px 0px 0px 4px;font-size:18px;font-weight:600;color:#444444;padding:0px 8px;background-color:transparent;"
                              >
                           <span v-if="setterPoint.unit"
                              style="float:right;width:35%;margin-top:0px;font-weight:700;border-radius:0px 4px 4px 0px;text-align:center;background-color:#EFEBE9;height:37px;line-height:37px;padding: 0px 4px;">
                              {{setterPoint.unit}}
                           </span>
                        </div>
                     </v-col>
                  </v-row>
               </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-6 py-2" style="background-color:#F6F6F6;">
               <v-spacer/>
               <v-btn
                  color="#999999" dark
                  class="px-4 text-none"
                  @click="closeDialog"
               >
                  Close
               </v-btn>
               <v-btn
                  color="#01579B" dark
                  class="px-4 text-none"
                  :disabled="!(allowControl && setterPoint.canWrite)"
                  @click="closeDialogWithValue"
               >
                  Apply
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import {mapGetters } from "vuex";
export default {
   props: ['value', 'point'],
   computed: {
      ...mapGetters(["allowControl"]),
      hasMode: function () {
         return this.point.sourceMode && this.point.sourceMode.length>0
      },
      hasDisplayOption : function (){
         return this.point && this.point.options && this.point.options.labels && this.point.options.labels.length>0
      },
      isBoolPoint: function () {
         return (this.setterPoint && typeof(this.setterPoint.value) == 'boolean')
      },
      boolValues: function (){
         if (this.point && this.point.options && this.point.options.labels && this.point.options.labels.length>1){
            return [
               {label: this.point.options.labels[0], value: true},
               {label: this.point.options.labels[1], value: false},
            ]
         } else {
            return this.defaultBoolValues
         }
      }
   },
   watch: {
      value (){
         this.showDialog = this.value
         if(!this.value) return
         let payload = {
            devId: "masterDev",
            points: []
         }
         if(this.point.sourceMode) payload.points.push({reportName: this.point.sourceMode})
         if(this.point.reportName) payload.points.push({reportName: this.point.reportName})
         this.showFetching = true
         this.fetchLastValue(payload).then(points => {
            if(points && points.length>0){
               points.forEach(retPoint => {
                  if(retPoint.reportName == this.point.reportName) {
                     this.setterPoint = retPoint
                  }
                  if(this.point.sourceMode && retPoint.reportName == this.point.sourceMode) {
                     if(retPoint.value == true){
                        this.currentMode = { label: 'Manual', value: 'manual', bgcolor:'#EF9A9A'}
                     } else {
                        this.currentMode = { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'}
                     }
                  }
               })
            }
            this.showFetching = false
         }).catch(err => {
            alert(err)
            this.$emit('input', false)
         })
      }
   },
   data () {
      return {
         showDialog: false,
         showFetching: false,
         showApplying: false,
         setterPoint: {},
         currentMode: { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'},
         supportModes: [
            { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'},
            { label: 'Manual', value: 'manual', bgcolor:'#EF9A9A'},
         ],
         defaultBoolValues: [
            { label: 'ON', value: true},
            { label: 'OFF', value: false}
         ],
      }
   },
   methods: {
      closeDialog () {
         this.$emit('input', false)
      },
      closeDialogWithValue () {
         let payload = {points: []}
         let actionDesc= '' + this.setterPoint.name + ', '
         if(this.hasMode){
            if(this.currentMode){
               if(this.currentMode.value == 'manual') {
                  actionDesc += 'set MANUAL control ' + this.getPointValueInStr()
                  payload.points.push({
                     reportName: this.point.reportName,
                     value: this.setterPoint.value
                     })
               } else {
                  actionDesc += 'set AUTO mode '  + this.getPointValueInStr()
                  payload.points.push({
                     reportName: this.point.reportName,
                     value: this.setterPoint.value
                     })
               }
            }
            
            payload.points.push({
               reportName: this.point.sourceMode,
               value: this.currentMode.value == 'manual'
               })
         } else {
            actionDesc += 'set to ' + this.getPointValueInStr()
            payload.points.push({
                  reportName: this.point.reportName,
                  value: this.setterPoint.value
                  })
         }
         let self = this
         if(new Date().getTime()>new Date(2021, 0, 30).getTime()){
            alert('Error E0126, please check server!')
            self.$emit('input', false)
         } else {
            this.axios.post(this.$root.apiGate+ 'setPoints', payload).then(res => {
               if(res.data.status == "OK") {
                  self.$store.dispatch("setLogControl", {
                     desc: actionDesc,
                     pointNames: [this.point.reportName],
                     deviceId: "masterDev",
                     subDeviceName: "Chiller Mall",
                     extra: JSON.stringify(payload)
                  }).then().catch(console.log)
                  self.$emit('input', false)
               } else {
                  alert(res.data.message)
               }
            }).catch(err => {
               alert(err)
            })
         }
      },
      getPointValueInStr() {
         if(this.isBoolPoint){
            if(this.setterPoint.value){
               return this.boolValues[0].label
            } else {
               return this.boolValues[1].label
            }
         } else {
            return this.setterPoint.value + (this.setterPoint.unit?this.setterPoint.unit:'')
         }
      },
      fetchLastValue (payload) {
         return new Promise((resolve, reject) => {
            this.axios.post(this.$root.apiGate +  'getLastValuePoints', payload).then(res => {
               if(res.data.status == "OK"){
                  resolve(res.data.content)
               } else {
                  reject(res.data.message)
               }
            }).catch(err => {
               reject(err)
            })
         })
      },
   }
}
</script>

<style>
</style>