<template>
    <div style="min-height:270px;max-height:270px;width:100%;padding:4px 8px;">
        <div v-if="title && title.length"
            style="width:100%;text-align:center;height:34px;color:#2E7D32;font-size:20px;text-align:center;line-height:34px;font-weight:600;background-color:#A5D6A7;">
            {{title}}
        </div>

        <div style="height:100%;min-height:240px;max-height:270px;width:100%;margin-top:4px;">
            <vue-chart
                style="width:100%;height:100%;min-height:240px;max-height:270px;"
                type="bar" 
                v-if="showChart"
                :data="chartData" 
                :options="chartOptions"></vue-chart>
        </div>
    </div>
</template>
<script>
let moment = require('moment')
moment.locale("vi")
export default {
    props: ['title', 'colors', 'inputItems', 'loadIndex'],
    watch: {
        inputItems: function() {        
            this.processBlock()
        },
        loadIndex: function(){
            let self = this
            this.showChart = false
            setTimeout(()=>{
                self.showChart = true
            },100)
        }
    },
    data () {
        return {
            showChart: true,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "",
                        data: [],
                        backgroundColor: '#86B8FD'
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                animation: { duration: 0 },
                scales: {
                    yAxes: [{
                        ticks: { beginAtZero:true }
                    }]
                },
                legend: { display: false },
                tooltips: { enabled: true }
            },
            items: []
        }
    },
    methods: {
        clearChartData: function () {
            this.chartData.labels = []
            this.chartData.datasets = [{
                label: [],
                data: [],
                backgroundColor: '#86B8FD'
            }]
        },
        processBlock: function (){
            this.chartData.labels = []
            this.chartData.datasets = []
            this.chartData.datasets.push({
                label: "LastMonth",
                data: [],
                backgroundColor: this.colors[0]
            })
            this.chartData.datasets.push({
                label: "ThisMonth",
                data: [],
                backgroundColor: this.colors[1]
            })

            this.inputItems.forEach(month => {
                this.chartData.labels.push(month.label)
                this.chartData.datasets[0].data.push(month.data.preValue)
                this.chartData.datasets[1].data.push(month.data.thisValue)
            })
        },
        buildTimeFrame: function(blocks){
            console.log(blocks)
            let noHour = (this.dateRange.to.getTime() - this.dateRange.from.getTime())/(60*60*1000)
            let blockUnitInHour = 1;
            if(noHour > 72){
                blockUnitInHour = 24
            }
            const noBlock = Math.round(noHour/blockUnitInHour)
            for(var i=0; i< noBlock; i++){
                let frameValue = 0;
                let frameFromDate = new Date(this.dateRange.from.getTime() + (i*blockUnitInHour*60*60*1000))
                let frameToDate = new Date(this.dateRange.from.getTime() + ((i+1)*blockUnitInHour*60*60*1000))
                
                let j=0;
                this.fields.forEach(field => {
                    let inFrameBlocks = blocks.filter(b => {
                        var blockDate = new Date(b.date)
                        return (b.name==field.reportName) && (blockDate.getTime() >= frameFromDate.getTime() && blockDate.getTime() < frameToDate.getTime())
                    })

                    // default is calculate averate
                    if(inFrameBlocks&&inFrameBlocks.length>0){
                        let sum = 0
                        inFrameBlocks.forEach(block => {
                            sum += block.value
                        });
                        frameValue = (sum*1.0)/inFrameBlocks.length
                    }

                    if(this.isCount){
                        frameValue = inFrameBlocks.length
                    }
                    // remove duplicate
                    if(this.isSumValue){
                        inFrameBlocks.forEach(block => {
                            frameValue += block.value
                        });
                    }

                    if(this.isCountDevice) {
                        const deviceArray = [];
                        inFrameBlocks.forEach(block => {
                            if(block.value){
                                deviceArray.push(block.deviceId)
                            }
                        });
                        let unique = [...new Set(deviceArray)];
                        frameValue = unique.length
                    }

                    frameValue = Math.round(frameValue*100)/100
                    this.chartData.datasets[j].data.push(frameValue)
                    j++;
                })

                if(blockUnitInHour==1){
                    this.chartData.labels.push(new moment(frameFromDate).format("HH"))
                }
                if(blockUnitInHour==24){
                    if(noBlock > 20){
                        this.chartData.labels.push(new moment(frameFromDate).format("DD"))
                    } else{
                        this.chartData.labels.push(new moment(frameFromDate).format("ddd DD/MM"))
                    }
                }
            }

        }
    },
    mounted: function () {
        this.$nextTick(() => {
            this.processBlock()
        })
    }
}
</script>
