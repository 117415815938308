exports.points = [
   {label:'Energy IntoLoad', modal:'accu_xxxxx_Energy_IntoLoad'},
   {label:'Energy OutLoad', modal:'accu_xxxxx_Energy_OutLoad'},
   {label:'Frequency', modal:'xxxxx_Frequency'},

   {label:'Power Total', modal:'xxxxx_PowerTotal'},
   {label:'Apparent PowerTotal', modal:'xxxxx_Apparent_PowerTotal'},
   {label:'Reactive PowerTotal', modal:'xxxxx_Reactive_PowerTotal'},
   {label:'Power A', modal:'xxxxx_PowerA'},
   {label:'Power B', modal:'xxxxx_PowerB'},
   {label:'Power C', modal:'xxxxx_PowerC'},

   {label:'Current Avg', modal:'xxxxx_Current_Avg'},
   {label:'Current A', modal:'xxxxx_Current_A'},
   {label:'Current B', modal:'xxxxx_Current_B'},
   {label:'Current C', modal:'xxxxx_Current_C'},
   {label:'Current N', modal:'xxxxx_Current_N'},

   {label:'Voltage LN Avg', modal:'xxxxx_Voltage_LN_Avg'},
   {label:'Voltage LL Avg', modal:'xxxxx_Voltage_LL_Avg'},
   {label:'Voltage AB', modal:'xxxxx_Voltage_AB'},
   {label:'Voltage BC', modal:'xxxxx_Voltage_BC'},
   {label:'Voltage CA', modal:'xxxxx_Voltage_CA'},
   {label:'Voltage AN', modal:'xxxxx_Voltage_AN'},
   {label:'Voltage BN', modal:'xxxxx_Voltage_BN'},
   {label:'Voltage CN', modal:'xxxxx_Voltage_CN'},

   {label:'ReactiveEnergy IntoLoad', modal:'xxxxx_ReactiveEnergy_IntoLoad'},
   {label:'ReactiveEnergy OutLoad', modal:'xxxxx_ReactiveEnergy_OutLoad'},
   {label:'ApparentEnergy IntoLoad', modal:'xxxxx_ApparentEnergy_IntoLoad'},
   {label:'ApparentEnergy OutLoad', modal:'xxxxx_ApparentEnergy_OutLoad'},

   {label:'THDF Current A', modal:'xxxxx_THDF_Current_A'},
   {label:'THDF Current B', modal:'xxxxx_THDF_Current_B'},
   {label:'THDF Current C', modal:'xxxxx_THDF_Current_C'},
   {label:'THDF Current N', modal:'xxxxx_THDF_Current_N'},
   {label:'THDF Voltage AB', modal:'xxxxx_THDF_Voltage_AB'},
   {label:'THDF Voltage BC', modal:'xxxxx_THDF_Voltage_BC'},

   {label:'THDF Voltage CA', modal:'xxxxx_THDF_Voltage_CA'},
   {label:'THDF Voltage LN', modal:'xxxxx_THDF_Voltage_LN'},
   {label:'Power Factor', modal:'xxxxx_PowerFactor'},
   
]