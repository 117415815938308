<template>
   <div v-if="value">
      <v-dialog persistent v-model="showDialog" width="640" >
         <v-card>
            <v-card-title
               color="primary" class="py-0 pr-2 pl-3"
               style="background-color:#01579B;color:white;"
               primary-title
            >
               {{title}}
               <v-spacer/>
               <v-btn icon
                  color="white"
                  @click="closeDialog">
                  <v-icon>close</v-icon>
               </v-btn>
            </v-card-title>

            <v-card-text class="py-2" style="background-color:#F6F6F6;">
               <v-container
                  fluid style="font-size:18px;">
                  <v-row align="center">
                     <v-col cols="12">
                        <v-text-field
                           v-model='editorContent'
                           single-line outlined dense
                           style="background-color:white;"
                           >
                        </v-text-field>
                     </v-col>
                  </v-row>
               </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-6 py-2" style="background-color:#F6F6F6;">
               <v-spacer/>
               <v-btn
                  color="#999999" dark
                  class="px-4 text-none"
                  @click="closeDialog"
               >
                  Close
               </v-btn>
               <v-btn
                  color="#01579B" dark
                  class="px-4 text-none"
                  @click="closeDialogAndEmit"
               >
                  Apply
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
export default {
   props: ['value', 'content', 'title'],
   computed: {
   },
   watch: {
      value (){
         this.showDialog = this.value
         if(this.showDialog)
            this.editorContent = this.content
      },
      // content () {
      //    this.editorContent = this.content
      // }
   },
   data () {
      return {
         showDialog: false,
         editorContent: ''
      }
   },
   methods: {
      closeDialog () {
         this.$emit('input', false)
      },
      closeDialogAndEmit() {
         if(this.editorContent.length>0){
            this.$emit('input', false)
            this.$emit('returnValue', this.editorContent)
         } else {
            alert('String should not empty')
         }
      }
   }
}
</script>

<style>
</style>