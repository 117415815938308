exports.routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../components/HomePage.vue"),
  },
  // {
  //   path: "/report",
  //   name: "ReportHistory",
  //   props: {
  //     title: 'Report',
  //     pageKey: 'report',
  //     subDevList: [],
  //   },
  //   component: () => import("../components/ReportPage.vue"),
  // },
  // {
  //   path: "/setting",
  //   name: "Setting",
  //   component: () => import("../components/SettingPage.vue"),
  // },
  // {
  //   path: "/camera",
  //   name: "Camera",
  //   component: () => import("../components/Camera.vue"),
  // },
  // {
  //   path: "/remote89",
  //   name: "Remote",
  //   component: () => import("../components/RmotePage.vue"),
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("../components/LoginPage.vue"),
  // },
  // {
  //   path: "/bms/logs",
  //   name: "BMSLogs",
  //   component: () => import("../components/Logs/LogsPage.vue"),
  // },
  // {
  //   path: "/bms/events",
  //   name: "BMSEvents",
  //   component: () => import("../components/Events/EventsPage.vue"),
  // },
  // {
  //   path: "/user/profile",
  //   name: "UserProfile",
  //   component: () => import("../components/User/UserProfile.vue"),
  //   beforeEnter: (to, from, next) => {
  //     let userId = localStorage.getItem("uedi");
  //     if (userId) {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/admin/users",
  //   name: "SetupUsers",
  //   component: () => import("../components/Administrator/SetupUserPage.vue"),
  //   beforeEnter: (to, from, next) => {
  //     let lev = localStorage.getItem("leus");
  //     if (lev == "ninAnd033" || lev == "ASnind010") {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/ad/cmd",
  //   name: "AdCommand",
  //   component: () => import("../components/Administrator/AdCommand.vue"),
  //   beforeEnter: (to, from, next) => {
  //     let lev = localStorage.getItem("leus");
  //     if (lev == "ninAnd033" || lev == "ASnind010" || lev=="bberry") {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
  // {
  //   path: "/ad/file",
  //   name: "AdFile",
  //   component: () => import("../components/Administrator/AdFile.vue"),
  //   beforeEnter: (to, from, next) => {
  //     let lev = localStorage.getItem("leus");
  //     if (lev == "ninAnd033" || lev == "ASnind010" || lev=="bberry") {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  // },
];
