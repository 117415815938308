exports.navigators = [
  // {
  //   name: "Home",
  //   displayName: "Trang chủ",
  //   index: 1,
  //   system: "BMS",
  //   icon: "home",
  //   to: '/'
  // },
  {
    name: "Camera",
    displayName: "Camera",
    index: 2,
    system: "BMS",
    icon: "camera_alt",
    to:"/"
  },
  // {
  //   name: "ReportHistory",
  //   displayName: "Lịch sử",
  //   index: 3,
  //   system: "BMS",
  //   icon: "bar_chart",
  //   to:'report'
  // },
  // {
  //   name: "Setting",
  //   displayName: "Cài đặt",
  //   index: 4,
  //   system: "BMS",
  //   icon: "settings",
  //   to:"setting"
  // }
];
