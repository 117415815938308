<template>
   <div class="picker">
      <div class="displayPara" style="color:#795548;">
         <div style="font-size:11px;display:inline-block;line-height:18px;min-width:22px;width:22px;height:22px;text-align:center;background-color:#795548;color:#FFFFFF;padding:2px;border-radius:50%;">
               Pr
         </div>
         <template v-if="value">
            {{value}}: 
            <v-btn small text dense 
               @click="prepareEditPara"
               style="color:#795548;margin-right:4px;line-height:16px;min-width:20px;padding:0;margin:0px;margin-top:-2px;height:24px;font-weight:700;font-size:14px;">
               {{paraValue}}</v-btn>
         </template>
         <template v-else>
            <span style="color:#999999;margin-left: 6px;">EMPTY</span>
         </template>
      </div>
      <v-btn text class="searchButton" style="margin:0px;padding:0px;min-width:40px;height:30px;"
         @click="dialogSearch=true;">
         <v-icon style="color:white;font-size: 20px;">
            search
         </v-icon>
      </v-btn>
      <v-dialog
         v-model="dialogSearch"
         persistent
         width="520px">
         <v-card>
            <v-card-title style="background-color:#607D8B;padding: 4px 8px;color:white;">
               <template v-if="!isCreateMode && !isEditMode">Find parameter</template>
               <template v-if="isCreateMode">Create parameter</template>
               <template v-if="isEditMode">Edit parameter</template>
               <v-spacer/>
               <v-btn style="margin:0px;" text icon
                  @click="isCreateMode=false;isEditMode=false;dialogSearch=false;">
                  <v-icon color="white">clear </v-icon>
               </v-btn>
            </v-card-title>
            <v-card-text class="pa-4 pb-1">
               <div v-if="isCreateMode==false && isEditMode==false"
                  style="padding: 2px 6px;">
                  <v-text-field
                     v-model="searchString"
                     label="Search"
                     style="width:calc(100% - 100px);vertical-align:top;;display:inline-block;"
                     single-line clearable
                     hide-details
                     ></v-text-field>
                  <v-btn color="primary" dark class="px-2"
                     style="vertical-align:top;text-transform:none;font-size:14px;float:right;height:40px;"
                     @click="prepareCreateNewPara">
                     Add Para
                  </v-btn>
               </div>

               <div v-if="isCreateMode || isEditMode" style="padding:6px 8px;">
                  <v-text-field
                     style="max-width:400px;"
                     v-model="newPara.name"
                     :disabled="isEditMode"
                     label="Name *"
                     type="text">
                  </v-text-field>
                  <v-text-field
                     style="max-width:400px;"
                     v-model="newPara.value"
                     label="Value *"
                     type="text">
                  </v-text-field>
                  <v-text-field
                     style="max-width:400px;"
                     v-model="newPara.dataType"
                     label="Type *"
                     type="text">
                  </v-text-field>
                  <div class="mt-4" style="width:100%;height:40px;">
                     <v-btn dark @click="createNewPara" v-if="isCreateMode"
                        style="float:right;text-transform:none;" color="primary">
                        Create
                     </v-btn>
                     <v-btn dark @click="editPara" v-if="isEditMode"
                        style="float:right;text-transform:none;" color="primary">
                        Save
                     </v-btn>
                     <v-btn @click="isCreateMode=false;isEditMode=false;"
                        class="mr-2"
                        dark style="float:right;text-transform:none;" color="#AAAAAA">
                        Cancel
                     </v-btn>
                  </div>
               </div>

               <v-data-table
                     v-if="!isEditMode && !isCreateMode && paras"
                     :headers="headers"
                     :items="paras"
                     :search="searchString"
                     hide-default-footer
                     dense
                  >
                     <template v-slot:item.name="{ item }">
                        <v-btn text small style="text-transform:none;"
                           @click="paraPicked(item)">
                        {{ item.name }}
                        </v-btn>
                     </template>
                     <template v-slot:no-results>
                     <v-alert :value="true" color="#CDCDCD" icon="warning" style="color:#111111;">
                        Your search for "{{ searchString }}" found no results.
                     </v-alert>
                     </template>
               </v-data-table>
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>
<script>
export default {
   props: ['value', 'paras'],
   computed: {
      paraValue: function () {
         if(this.value){
            if(this.paras && this.paras.length>0){
               let foundPara = this.paras.find(p => p.name==this.value)
               if(foundPara) {
                  return foundPara.value
               }
            }
         }
         return ''
      }
   },
   data () {
      return {
         searchString: '',
         dialogSearch: false,
         headers: [
            { text: 'Name', value: 'name' },
            { text: 'Value', value: 'value' },
            { text: 'Type', value: 'dataType' }
         ],
         isCreateMode: false,
         isEditMode: false,
         newPara: {}
      }
   },
   methods: {
      prepareCreateNewPara () {
         this.isCreateMode = true
         this.newPara = {
            name: this.searchString,
            value: '',
            dataType: 'number'
         }
      },
      prepareEditPara () {
         this.isCreateMode = false
         this.isEditMode = true
         this.dialogSearch = true
         this.newPara = this.paras.find(p => p.name == this.value)
      },
      paraPicked (para) {
         this.dialogSearch = false
         this.$emit('on-picked', para)
      },
      createNewPara () {
         if(!this.newPara.name || this.newPara.name.length<=0) {
               alert('Name invalid')
               return
         }
         if(this.newPara.value==undefined || this.newPara.value==null || this.newPara.value.length<=0) {
               alert('Value invalid')
               return
         }
         if(!this.newPara.dataType || this.newPara.dataType.length<=0) {
               alert('Type invalid')
               return
         }
         if(this.paras.find(p=>p.name == this.newPara.name)) {
               alert('Name exist, please pick another name')
               return
         }
         let self = this
         let payload = {
               name: this.newPara.name,
               value: this.newPara.value,
               dataType: this.newPara.dataType
            }
         
         this.paras.push(payload)
         this.storeParas().then(()=>{
            self.searchString = ''
            self.isCreateMode = false
            self.paraPicked(payload)
         }).catch(alert)
      },
      storeParas () {
         return new Promise((resolve, reject) => {
            this.axios.post(this.$root.apiGate + 'store/storeSysObject', 
               { devId: 'masterDev', key: this.$root.parasKeys, content:this.paras}).then((response) => {
               if (response.data.status === 'OK') {
                  resolve()
               } else{
                  reject(response.data.message)
               }
            }).catch(reject)
         })
      },
      editPara () {
         if(this.newPara.value==undefined || this.newPara.value==null || this.newPara.value.length<=0) {
               alert('Value invalid')
               return
         }
         if(!this.newPara.dataType || this.newPara.dataType.length<=0) {
               alert('Type invalid')
               return
         }
         let self = this
         let matchPara = this.paras.find(p => p.name == this.newPara.name)
         if(!matchPara) {
            alert("Save error!!")
            return
         }

         matchPara.value = this.newPara.value
         matchPara.type = this.newPara.dataType
         this.storeParas().then(()=>{
            self.searchString = ''
            self.isCreateMode = false
            self.isEditMode = false
            self.paraPicked(matchPara)
         }).catch(alert)
      }
   },
   mounted () {
      this.$nextTick(() => {
         console.log("on Para picker " + this.value)
      })
   }
}
</script>

<style scoped>
   .picker {
      min-width:160px;
      background-color:#F2F2F2;
      border:1px solid #999999;
      border-radius:4px;
   }
   .displayPara {
      vertical-align: top;
      display:inline-block;
      height: 30px;
      width: calc(100% - 50px);
      line-height: 30px;
      margin: auto;
      font-size: 11px;
      font-weight: 500;
      padding: 0px 6px;
      font-weight:400;
   }
   .searchButton {
      vertical-align: top;
      display: inline-block;
      padding: 2px;
      height: 30px;
      width: 30px;
      background-color: #999999;
      color: white;
      font-size: 20px;
      border-radius: 0px 2px 2px 0px;
      float:right;
   }
</style>