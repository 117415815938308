exports.configs = {
   logApi: 'http://localhost:3456/',
   api: 'http://localhost:3456/',
   roles: {
      sadmin: "ASnind010",
      admin: "ninAnd033",
      operator: "Perio020",
      service: "neergeen040",
      guest: "tugets050",
   },
   controlRoles: ["ASnind010", "ninAnd033", "Perio020", "neergeen040"],
   adminRoles: ["ASnind010", "ninAnd033"]
}