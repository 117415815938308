<template>
   <div v-if="value">
      <v-dialog persistent v-model="showDialog" width="640" >
         <v-card>
            <v-card-title
               color="primary" class="py-0 pr-2 pl-3"
               style="background-color:#01579B;color:white;"
               primary-title
            >
               Report Builder
               <v-spacer/>
               <v-btn icon
                  color="white"
                  @click="closeDialog">
                  <v-icon>close</v-icon>
               </v-btn>
            </v-card-title>

            <v-card-text class="pt-4 pb-4" style="background-color:#F6F6F6;min-height:400px;">
               <v-container
                  fluid style="font-size:18px;">
                  <v-row align="center">
                  </v-row>
               </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-6 py-2" style="background-color:#F6F6F6;">
               <v-spacer/>
               <v-btn
                  color="#999999" dark
                  class="px-4 text-none"
                  @click="closeDialog"
               >
                  Close
               </v-btn>
               <v-btn
                  color="#01579B" dark
                  class="px-4 text-none"
                  @click="closeDialog"
               >
                  Apply
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
export default {
   props: ['value', 'reportOptions', 'points'],
   computed: {
   },
   watch: {
      value (){
         this.showDialog = this.value
      }
   },
   data () {
      return {
         showDialog: false,
         currentMode: { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'},
         supportModes: [
            { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'},
            { label: 'Manual', value: 'manual', bgcolor:'#EF9A9A'},
         ]
      }
   },
   methods: {
      closeDialog () {
         this.$emit('input', false)
      }
   }
}
</script>

<style>
</style>