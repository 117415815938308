<template>
   <div v-if="value">
      <v-dialog persistent v-model="showDialog" width="640" >
         <v-card>
            <v-card-title
               color="primary" class="py-0 pr-2 pl-3"
               style="background-color:#01579B;color:white;"
               primary-title
            >
               Chart Builder
               <v-spacer/>
               <v-btn icon
                  color="white"
                  @click="closeDialog">
                  <v-icon>close</v-icon>
               </v-btn>
            </v-card-title>

            <v-card-text class="pt-4 pb-4" style="background-color:#F6F6F6;min-height:400px;">
               <v-container v-if="chartItem"
                  fluid style="font-size:16px;">

                  <v-row>
                     <v-col cols="3">
                        Data Point
                     </v-col>
                     <v-col cols="1">
                        <div style="display:inline-block;">
                           <point-picker 
                              :subDevList="subDevList"
                              :points='points' 
                              @pointPicked="onPointPicked"/>
                        </div>
                     </v-col>
                     <v-col cols="8">
                        <div style="width:100%;font-weight:600;display:inline_block;background-color:white;border:1px solid #AAAAAA;border-radius:0 4px 4px 0;height:40px;line-height:40px;padding: 0px 10px;">
                           {{chartItem.pointName}}
                        </div>
                     </v-col>
                     
                  </v-row>

                  <v-row align="center">
                     <v-col cols="3">
                        Chart Title
                     </v-col>
                     <v-col cols="9">
                        <v-text-field
                           style="background-color:white;"
                           dense
                           outlined
                           v-model='chartItem.title'
                        ></v-text-field>
                     </v-col>
                  </v-row>

                  <v-row align="center">
                     <v-col cols="3">
                        Description
                     </v-col>
                     <v-col cols="9">
                        <v-text-field
                           style="background-color:white;"
                           dense
                           outlined
                           v-model='chartItem.desc'
                        ></v-text-field>
                     </v-col>
                  </v-row>

                  <v-row align="center">
                     <v-col cols="3">
                        Chart Width
                     </v-col>
                     <v-col cols="9">
                        <v-select class="ma-0" color="primary"
                           style="background-color:white;"
                           v-model="chartItem.cols"
                           :items="supportCols"
                           item-text="label"
                           item-value="value"
                           label=""
                           persistent-hint
                           single-line
                           outlined dense
                        ></v-select>
                     </v-col>
                  </v-row>

                  <v-row align="center">
                     <v-col cols="3">
                        Color 1
                     </v-col>
                     <v-col cols="3">
                        <color-picker v-model='chartItem.colors[0]'/>
                     </v-col>

                     <v-col cols="3">
                        Color 2
                     </v-col>
                     <v-col cols="3">
                        <color-picker v-model='chartItem.colors[1]'/>
                     </v-col>
                  </v-row>

                  
               </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-6 py-2" style="background-color:#F6F6F6;">
               <v-spacer/>
               <v-btn
                  color="#999999" dark
                  class="px-4 text-none"
                  @click="closeDialog"
               >
                  Close
               </v-btn>
               <v-btn
                  color="#01579B" dark
                  class="px-4 text-none"
                  @click="$emit('saveDone', chartItem)"
               >
                  Apply
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
export default {
   props: ['value', 'chartItem', 'points', 'subDevList'],
   computed: {
   },
   watch: {
      value (){
         this.showDialog = this.value
      }
   },
   data () {
      return {
         showDialog: false,
         supportCols: [
            { label: '1/4 Width', value:3},
            { label: '1/3 Width', value:4},
            { label: '1/2 Width', value:6},
            { label: '2/3 Width', value:8},
            { label: '3/4 Width', value:9},
            { label: 'Full Width', value:12},
         ]
      }
   },
   methods: {
      closeDialog () {
         this.$emit('input', false)
      },
      onPointPicked(point){
         this.chartItem.pointName = point.reportName
      }
   }
}
</script>

<style>
</style>