<template>
   <v-card flat text class="ma-0 pa-0" style="background-color:transparent;">
      <string-editor-dialog 
            v-model="nameDialog" 
            :content='nameOnEditor' 
            :title='nameDialogTitle'
            @returnValue="onStringEditorReturn"/>
      <v-card-title class="ma-0 pa-0">
         <v-spacer/>
         <span style="line-height:40px;font-weight:400;font-size:16px;margin-right:6px;">Profile:</span>
         <v-select
            :items="allProfiles"
            v-model="activeProfile"
            item-text="profileName"
            label=""
            style="min-width:180px;display:inline-block;background-color:white;"
            outlined dense hide-details single-line
            return-object
            @change="notifyActiveProfileToParent"
         ></v-select>
         <v-menu offset-y>
            <template v-slot:activator="{ on }">
               <v-btn dark
                  color="#444444"
                  class="px-2"
                  style="text-transform:none;font-weight:400;"
                  icon
                  v-on="on"
               >
                  <v-icon>more_vert</v-icon>
               </v-btn>
            </template>
            <v-list style="border-radius:0px;z-index:100;">
               <v-list-item-group>
                  <v-list-item style="z-index:10;">
                     <v-list-item-title 
                        style="font-size:14px;"
                        class="cursor-pointer"
                        @click="addNewProfile"
                        >
                        Add New Profile
                     </v-list-item-title>
                  </v-list-item>

                  <template v-if="activeProfile">
                     <v-list-item style="z-index:10;">
                        <v-list-item-title 
                           style="font-size:14px;;"
                           class="cursor-pointer"
                           @click="renameThisProfile"
                           >
                           Rename This Profile
                        </v-list-item-title>
                     </v-list-item>

                     <v-list-item style="z-index:10;">
                        <v-list-item-title 
                           style="font-size:14px;color:red;"
                           class="cursor-pointer"
                           @click="deleteThisProfile"
                           >
                           Delete This Profile
                        </v-list-item-title>
                     </v-list-item>
                  </template>

                  <v-list-item style="z-index:10;">
                        <v-list-item-title 
                           style="font-size:14px;"
                           class="cursor-pointer"
                           @click="saveAll"
                           >
                           Save all
                        </v-list-item-title>
                     </v-list-item>
               </v-list-item-group>
            </v-list>
         </v-menu>
      </v-card-title>
   </v-card>
</template>

<script>
export default {
   props: ['value', 'pageKey'],
   watch: {},
   data () {
      return {
         nameDialog: '',
         nameOnEditor: '',
         nameDialogTitle: '',
         newProfileMode: false,
         activeProfile: null,
         allProfiles:[]
      }
   },
   methods: {
      notifyActiveProfileToParent () {
         this.$emit('input', this.activeProfile)
      },
      onStringEditorReturn(value) {
         if(this.newProfileMode) {
            let newProfile = {
               profileName: value,
               meta: {},
               content: {}
            }
            this.allProfiles.push(newProfile)
            this.activeProfile = this.allProfiles[this.allProfiles.length-1]
         } else {
            this.activeProfile.profileName = value
         }
         this.notifyActiveProfileToParent()
      },
      addNewProfile () {
         this.newProfileMode = true
         this.nameOnEditor = ""
         this.nameDialogTitle= 'Create New Profile'
         this.nameDialog = true
      },
      deleteThisProfile () {
         if (this.activeProfile) {
            this.allProfiles = this.allProfiles.filter(p => p.profileName != this.activeProfile.profileName)
            if(this.allProfiles.length>0){
               this.activeProfile = this.allProfiles[0]
            } else {
               this.activeProfile = null
            }
            this.notifyActiveProfileToParent()
         }
      },
      renameThisProfile () {
         this.newProfileMode = false
         this.nameOnEditor = this.activeProfile.profileName
         this.nameDialogTitle= 'Rename Profile'
         this.nameDialog = true
      },
      saveAll () {
         this.axios.post(this.$root.apiGate + 'store/storeUIObject', 
            { devId: 'masterDev', key: this.pageKey, content:this.allProfiles}).then((response) => {
            if (response.data.status === 'OK') {
               alert('Save successful!')
            } else{
               alert('Error: ' + response.data.message)
            }
         }).catch(err => {
            console.log(err)
         })
      },
      loadAllPageFromStore () {
         let self = this
         this.axios.post(this.$root.apiGate + 'store/getUIObject', 
            { devId: 'masterDev', key: this.pageKey}).then((response) => {
            self.allProfiles = []
            if (response.data.status === 'OK') {
               if(response.data.content && Array.isArray(response.data.content)){
                  self.allProfiles = response.data.content
               }
            } else{
               alert('Error: ' + response.data.message)
            }
            if(self.allProfiles.length>0){
               self.activeProfile = self.allProfiles[0]
            } else {
               self.allProfiles = [{
                  profileName: 'Untitled Profile',
                  content: {}
               }]
               self.activeProfile = self.allProfiles[0]
            }
            self.notifyActiveProfileToParent()
         }).catch(err => {
            alert('Error: ' + err.message)
            console.log(err)
         })
      },
   },
   mounted() {
      this.$nextTick(() => {
         this.loadAllPageFromStore()
      })
   },
}
</script>

<style>
   
</style>